<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp" style="min-height:90vh" class="pa-0">
    <v-card class="mt-3">
      <v-card-title>
        <v-btn :to="{name:'Home'}" icon class="mr-3">
          <pg-icon XL>i-go-back</pg-icon>
        </v-btn>&nbsp;Écrivez votre jeu
      </v-card-title>
      <v-card-text class="pa-0">
       C'est ici que ça se passe.
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PgIcon from "../../components/PGIcon";
export default {
  name: "WriteGame",
  components: {PgIcon}
}
</script>

<style scoped>

</style>